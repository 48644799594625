import react, { useEffect } from "react";
import { QRCodeCanvas } from "qrcode.react";
import { useGetParticipantTag } from "../../../application/hooks/queryHooks";
import './TagComponent.css';
import logo from "../../../application/assets/images/jalsalogo.png";
import qrcode from "../../../application/assets/images/qrcode.png";
import numberPrefixer from "../../../application/utils/numberPrefixer";






export const TagComponent = react.forwardRef(({ details, id }, ref) => {
    // console.log("details", details)
    let namelength = `${details?.lastName} ${details.firstName}}`.length;
    const qrcode2 = (
        <QRCodeCanvas
          id="qrCode"
          value={details?.qrCode}
          size={100}
          bgColor={"white"}
          level={"M"}
        />
    );
    const {data, isLoading} = useGetParticipantTag(details?.registrationNumber);
    return (
        
        <div ref={ref} className={`flex flex-col items-center h-full w-full overflow-y-auto`} id={id}>
            <div className="flex flex-col md:flex-row ">
                <div className={`card`}>
                    <div className="tag-hole">
                        <div id="hole"></div>
                    </div>
                    <div class="info">
                        <p  className="name !text-xl" style={{textAlign:"center", marginTop:"16pc"}}>{details.lastName.toUpperCase()}  {(details.lastName.length + details.firstName.length > 20) ? `${details.firstName.toUpperCase()[0]}.` : details.firstName.toUpperCase()}</p>
                        {
                            details.participantType.toUpperCase() === "MEMBER" &&
                        <p  className="name !text-xl text-center">{details.participantType} {details.membershipNumber}</p>
                        }
                        <div className="others">
                        <div className="bottom2 w-[38%]  ml-[13px]">{qrcode2}</div>
                    <div class="member-info">
                        <p style={{fontSize:"29px"}}>{ details.registrationNumber }</p>
                        <p>{(details.participantType === "Guest") ? "GUEST" : `${details.jamaatName} ${details.circuitName}`}</p>
                    </div>
                        </div>
                  
        </div>
                </div>
                <div className="my-4 md:mx-4 md:my-0"></div>
            </div>

        </div>
    );
});
