import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthentication from "../../application/hooks/useAuthentication";
import TopNavigationComponent from "../components/topnav/TopNavigationComponent";
import NavigationLayout from "../layout/NavigationLayout";
import './RegistrationContainer.css';
import MemberVirtualCardRegistration from "../components/register/MemberVirtualCardRegistration";
import GuestVirtualCardRegistration from "../components/register/GuestVirtualCardRegistration";
import DisplayVirtualTags from "../components/printag/DisplayVirtualTags";

const AddVirtualCardContainer = () => {
    
    useAuthentication();
    const navigate = useNavigate();
    const location = useLocation();
    const userAuthLevel = useSelector(state => state.user.userAuthLevel);
    const activeOption =  location.pathname.split("/")[2] ?? "virtual";
   
    const navigateOptions = (activeOpt) => {
        if(activeOpt === "virtual")
        {
            navigate(`/virtual`);
        }
        else{
            
            navigate(`/virtual/${activeOpt}`);
        }
    }

    const navOptions = [
        {
            state: "virtual",
            displayText: "Member registration"
        },
        {
            state: "guest",
            displayText: "Guest registration" 
        }
    ]




    return (
        <NavigationLayout>
            <TopNavigationComponent navOptions={navOptions} activeOption={activeOption} setActiveOption={navigateOptions}/>
            <div className="w-full h-full py-5 md:h-[90%] overflow-y-auto">
                {
                    (activeOption === "virtual") && <MemberVirtualCardRegistration />
                }
                {
                    activeOption === "guest" && <GuestVirtualCardRegistration />
                }
                {
                    (activeOption == "tags" ) && <DisplayVirtualTags />
                }
            </div>
        </NavigationLayout>
    );
}

export default AddVirtualCardContainer;