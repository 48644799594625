import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import services from "../../../ioc/services";
import MainButton from "../buttons/MainButton";
import FormikControl from "../formik/FormikControl";
import './RegisterComponent.css';
import { SpinnerCircular } from 'spinners-react';
import DisabledButton from "../buttons/DisabledButton";
import { useSelector } from "react-redux";
import { useGetGuestParticipantsByCreatorNo, useGetParticipantTypes } from "../../../application/hooks/queryHooks";
import {useNavigate} from "react-router"
import { CheckBox } from "../table/CheckBox";
import { getParticipantTypes } from "../../../infrastructure/api/user/userRequests";

const MemberVirtualCardRegistration = () => {
    const userId = useSelector(state => state.user.userId);
    const [isRegistering, setRegistering] = useState(false);
    const registeredGuestDetails = useGetGuestParticipantsByCreatorNo(userId);
    const user = useSelector(state => state.user);
    const userAuthLevel = user.userAuthLevel;
    const isMemberActive = userAuthLevel == "member";
    const isNazimActive = userAuthLevel == "nazim volunteer";
    const isAdminActive = userAuthLevel == "national" || userAuthLevel == "circuit" || userAuthLevel == "jamaat" || userAuthLevel == "admin";
    const navigate = useNavigate();

    const [typeOption, setTypeOption] = useState([]);

    const initialValues = {
        memberNo: '',
        registrationNumber: '',
    }

    const validationSchema = Yup.object({
        memberNo: Yup.string().required("Member Number is required"),
        registrationNumber: Yup.string().required("Registration Number is required"),
    });

    const displayInput = [
        {
            label: "Member No.",
            name: "memberNo",
            control: "input",
            placeholder: "Enter Member Number",
        },
        {
            label: "registration Number",
            name: "registrationNumber",
            control: "input",
            placeholder: "Enter registration number",
        }
    ]
    const registerMemberVirtualCard = (values, onSubmitProps) => {
     
        console.log("Register member virtual card")
        const memberDetails = {
            registrationNumber: values.registrationNumber,
            memberNo: values.memberNo
        }
        setRegistering(true);
        services.api.userRequests.registerMemberVirtualCard(memberDetails.memberNo, memberDetails.registrationNumber).then(res => {
           console.log({res})
            if (!res.data.succeeded) {
                services.toast.error(res.data.messages[0]);
            } else {
                services.toast.success(res.data.messages[0]);
                onSubmitProps.resetForm();
            }
            setRegistering(false);
        }).catch(error => {
            services.toast.error(error.message);
            setRegistering(false);
        })
    }
    return (
        <>
            <div className="view-guest-btn">
                <MainButton className="flex items-center" onClick={() => {navigate(`/virtual/tags`)}}><span>VIEW TAGS</span></MainButton>
            </div>
            <Formik
    initialValues={initialValues}
    validationSchema={validationSchema}
    onSubmit={registerMemberVirtualCard}
    validateOnChange={false}
>
    {formik => (
        <Form className="w-full max-w-lg mx-auto p-6 bg-white shadow-md rounded-lg space-y-6">
            <div className="space-y-4">
              
                {displayInput.map((d, index) => (
                                <FormikControl
                                    key={index * 0.5}
                                    label={d.label}
                                    name={d.name}
                                    placeholder={d.placeholder}
                                    options={d?.options}
                                    control={d.control}
                                     className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:border-green-500 focus:ring-1 focus:ring-green-500 shadow-sm disabled:opacity-50"
                                />
                            ))}
            </div>

            <div className="flex justify-center">
                {!isRegistering ? (
                    <MainButton
                        type="submit"
                        className="w-full bg-indigo-600 text-white py-3 rounded-md hover:bg-indigo-700 transition-all duration-200 shadow-md"
                    >
                        REGISTER
                    </MainButton>
                ) : (
                    <DisabledButton
                        disabled={true}
                        className="w-full bg-gray-300 text-gray-500 py-3 rounded-md flex items-center justify-center cursor-not-allowed"
                    >
                        <span>REGISTER</span>
                        <SpinnerCircular
                            className="ml-3"
                            color="white"
                            secondaryColor="#EEF0F2"
                            size={20}
                            thickness={150}
                            enabled={isRegistering}
                        />
                    </DisabledButton>
                )}
            </div>
        </Form>
    )}
</Formik>

        </>
    );
}

export default MemberVirtualCardRegistration;