import React, { useEffect, useMemo, useState } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table/dist/react-table.development";
import { COLUMNS } from "../../../application/utils/columns/ParticipantColumn";
import '../table/Table.css';
import PaginateComponent from "../table/PaginateComponent";
import MainButton from "../buttons/MainButton";
import { FaDownload, FaHome } from "react-icons/fa";
import { ExportMembers } from "../volunteer/ExportMembers";
///
const DisplayParticipants = ({ setIsViewParticipant, participantData }) => {

    const columns = useMemo(() => COLUMNS, []);

    const { getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        setPageSize,
        setGlobalFilter,
        selectedFlatRows,
    } = useTable({
        columns,
        data: participantData?.data,
    },
        useGlobalFilter,
        usePagination)

    const { pageIndex, pageSize } = state;
    let downloadData = participantData?.data.map(m => {
        return {
            RegistrationNo : m.registrationNumber,
            membershipNo : m.membershipNumber,
            FirstName: m.firstName,
            LastName: m.lastName,
            AuxiliaryBody: m.auxiliaryBody,
            ParticipantType: m.participantType
        }
    })
    return (
        <div className="w-full h-[80%] md:h-full  items-center md:w-[95%] ">
            <span className="opacity-[49%] tracking-[0.2em] font-bold flex justify-center md:my-[20px] text-[16px] md:text-[20px]">PARTICIPANTS(<span className="tracking-[0em]">{participantData?.title}</span>)</span>
            
            <div className="ml-10 flex justify-between">
            <ExportMembers apiData={downloadData} fileName={"Members"} />
                <MainButton onClick={() => { setIsViewParticipant(false) }} >
                    <span className="flex flex-end items-center"><FaHome /></span>
                </MainButton>
              
            </div>
            {
                participantData?.data?.length > 0 &&
                <div className="md:px-5 h-[95%]  overflow-y-auto">
                    <table  {...getTableProps()} className="table">
                        <thead className="thead">
                            {
                                headerGroups.map((headerGroup, index) => (
                                    <tr key={index} {...headerGroup.getHeaderGroupProps()} className="tr-head">
                                        <th>NAME</th>
                                        <th>Member Number</th>
                                        {
                                            headerGroup.headers.map((column, index) => (
                                                <th key={index * 0.5} {...column.getHeaderProps()}>{column.render('Header')}</th>
                                            ))
                                        }
                                    </tr>
                                ))
                            }

                        </thead>
                        <tbody {...getTableBodyProps()} className="tbody">
                            {
                                page.map((row, index) => {
                                    prepareRow(row)
                                    return (
                                        <React.Fragment key={index}>
                                            <tr  {...row.getRowProps()} className="tr-body">
                                                <td>{`${row.original.lastName} ${row.original.firstName}`}</td>
                                                <td>{row.original.membershipNumber}</td>
                                                {
                                                    row.cells.map((cell, index) => {
                                                        return <td key={index} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                                    })
                                                }
                                            </tr>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            }
            {
                participantData?.data?.length === 0 &&
                <div className="flex h-[80%] items-center justify-center">
                    <span className="opacity-[49%] tracking-[0.1em] font-bold  text-[12px] md:text-[14px]">NO RECORD TO DISPLAY</span>
                </div>
            }
            <PaginateComponent pageIndex={pageIndex} pageOptions={pageOptions} canNextPage={canNextPage} canPreviousPage={canPreviousPage} previousPage={previousPage} nextPage={nextPage} pageSize={pageSize} setPageSize={setPageSize} />
        </div >
    );
}

export default DisplayParticipants;