import React, { useState } from "react";
import Navbar from "../components/landing/Navbar";
import Hero from "../components/landing/Hero";
import LoginModal from "../components/landing/LoginModal";
import Countdown from "../components/landing/Countdown";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
const LoginPage = () => {
  const navigate = useNavigate();
  const userId = JSON.parse(localStorage.getItem("userDetails"))?.userId;
  const user = useSelector((state) => state.user);
  const userIdStore = user.userId;
  const userAuthLevel = user.userAuthLevel;
const loc = window.location.href.split("=");
const location = useLocation();
  
const [showModal, setShowModal] = useState(false);
const [modalMessage, setModalMessage] = useState("");
console.log(loc, "location");

  useEffect(() => {
    
if (location.pathname === "/scanned") {
  console.log("Entereeeeeeeeeeeeeeeeeeeeeeeeee")
  const queryParams = new URLSearchParams(location.search);
  const message = queryParams.get("message") || "Successfully Scanned";
  setModalMessage(message);
  setShowModal(true);
}
    if (userId !== undefined && userIdStore !== null) {
      if (
        userAuthLevel == "national" ||
        userAuthLevel == "circuit" ||
        userAuthLevel == "jamaat"
      )
        navigate("/dashboard");
      else if (userAuthLevel == "nazim volunteer") navigate("/departments");
      else navigate("/register");
    }
  }, [userId, userIdStore]);
  const [isModalActive, setIsModalActive] = useState(false);

  return (
    <>
      <Navbar setIsModalActive={setIsModalActive}/>
      <Hero setIsModalActive={setIsModalActive} />
      {showModal && <Modal message={modalMessage} onClose={() => setShowModal(false)} />}
      {isModalActive && <LoginModal setIsModalActive={setIsModalActive} />}
      <Countdown/>
    </>
  );
};

export default LoginPage;


const Modal = ({ message, onClose }) => (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm w-full">
      <p className="text-center text-lg font-medium">{message}</p>
      <button
        onClick={onClose}
        className="mt-4 w-full bg-green-600 text-white py-2 rounded hover:bg-green-700 transition duration-200"
      >
        Close
      </button>
    </div>
  </div>
);
