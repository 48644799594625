import React, { useState, useEffect } from 'react';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import MainButton from "../buttons/MainButton";
import ListVolunteer from './ListVolunteers';
import services from "../../../ioc/services";
const axios = require('axios');

export const ExportMembers = ({ apiData, fileName }) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
   
  
    const exportToCSV = (apiData, fileName) => {
      const ws = XLSX.utils.json_to_sheet(apiData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    };

    return (
        <div className='container'>
            <MainButton onClick={(e) => exportToCSV(apiData, fileName)}>
                Download
            </MainButton>
        </div>
    );
  
};